/** @jsx jsx */
import { graphql, Link } from 'gatsby'
import { jsx, Box } from 'theme-ui'

const WorkList = ({ data }) => {
  return (
    <Box
      sx={{
        width: ['90%', '80%', '70%', '70%'],
        textAlign: 'center',
        padding: '0 20px',
        margin: '4px',
      }}
    >
      <h1 sx={{ textAlign: 'center', marginBottom: '20px' }}>
        リクルート募集一覧
      </h1>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '15px',
        }}
      >
        {data.map((card, index) => {
          const jobData = card.node
          return (
            <Box
              key={index}
              sx={{
                flex: ['1 1 100%', '1 1 48%'],
                backgroundColor: '#f5f8fa',
                borderRadius: '10px',
                padding: '20px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s',
                textAlign: 'left',
                minHeight: ['100px', '250px'],
              }}
            >
              <h3
                sx={{
                  fontSize: ['16px', '18px'],
                  color: '#2980b9',
                  marginBottom: '10px',
                }}
              >
                【{jobData.position}】{jobData.required_skills}
              </h3>
              <p
                sx={{
                  fontSize: '12px',
                  color: '#7f8c8d',
                }}
              >
                {jobData.requirements}
              </p>
              <p
                sx={{
                  fontSize: '14px',
                  color: '#333',
                }}
              >
                {jobData.job_description}
              </p>
              <Box
                sx={{
                  background: '#f2f3f5',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  display: 'inline-block',
                  alignItems: 'flex-start',
                  fontSize: '15px',
                  marginRight: '1px',
                  color: '#666',
                  marginTop: '15px',
                }}
              >
                {jobData.location}
              </Box>
              {/* 給料の表示 */}
              <Box sx={{ marginTop: '15px' }}>
                <img src="/yen-icon.svg" alt="yen icon" />
                {jobData.salary ? (
                  <span
                    sx={{
                      fontWeight: '700',
                      color: '#f14b4d',
                      fontSize: '1.5rem',
                      marginLeft: '5px',
                    }}
                  >
                    {jobData.salary.toLocaleString()}
                  </span>
                ) : (
                  <span
                    sx={{
                      fontWeight: '700',
                      color: '#666',
                      fontSize: '1.5rem',
                      marginLeft: '5px',
                    }}
                  >
                    未定
                  </span>
                )}
                円／年
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default WorkList
