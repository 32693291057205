// /** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import React from 'react'
import { Fragment } from 'react'
import { Filter } from '../components/ProjectList/Filter'
import WorkList from '../components/ProjectList/WorkList'
import { graphql, Link } from 'gatsby'
import { BackgroundFill } from '../components/Common/BackgroundFill'

const ProjectList = ({ data }) => {
  const jobListDatas = data?.allMicrocmsJobList?.edges
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: ['column', 'column', 'row'],
          alignItems: ['center', 'center', 'flex-start'],
          width: '100%',
          padding: '5rem 0',
          position: 'relative',
        }}
      >
        <BackgroundFill background={'rgb(236, 241, 247)'} />
        <Filter />
        <WorkList data={jobListDatas} />
      </Box>
    </Box>
  )
}

export default ProjectList

export const query = graphql`
  query {
    allMicrocmsJobList {
      edges {
        node {
          id
          location
          required_skills
          position
          experience
          requirements
          job_description
          communication
          salary
        }
      }
    }
  }
`
