import React from 'react'

/** 부모 컴포넌트에 position: relative 필수*/
export const BackgroundFill = ({ background }) => {
  return (
    <div
      style={{
        backgroundColor: background,
        width: '100vw',
        height: '100%',
        position: 'absolute',
        top: '0%',
        left: '50%',
        transform: 'translateX(-50vw)',
        zIndex: '-1',
      }}
    ></div>
  )
}
